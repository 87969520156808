import dayjs from "dayjs";

import useAppInitData from "./useAppInitData";
import useCountdown from "./useCountdown";
import useGetPrizeDropsInfoData from "./useGetPrizeDropsInfoData";
import useIsGuest from "./useIsGuest";
import useLoginGuard from "./useLoginGuard";

type ModalOpen<K extends string> = (name: K) => void;

const usePrizeDrop = <K extends string>({
	separationTimer,
	routeName = "prize-drops",
	open = () => {},
	isOptimizedTimer = true
}: { separationTimer?: boolean; routeName?: "prize-drops"; open?: ModalOpen<K>; isOptimizedTimer?: boolean } = {}) => {
	const route = useRoute();
	const loginGuard = useLoginGuard();
	const { data: appInitData } = useAppInitData();
	const { data: prizeDropsData, pending } = useGetPrizeDropsInfoData({
		immediate: !!appInitData.value?.prizeDrops?.isActive
	});
	const isGuest = useIsGuest();

	const endOfDayDate = dayjs().endOf("day").toString();
	const prizeDropPopupCookie = useCookie("showPrizeDropPopup", {
		expires: new Date(endOfDayDate),
		path: "/"
	});

	const prizeDropActive = computed(() => !!appInitData.value?.prizeDrops?.isActive);
	const showActiveWidget = computed(() => prizeDropActive.value && !route.path.includes(routeName));

	const prizesLeftStill = computed(() =>
		prizeDropsData.value?.prizes?.reduce((acc, el) => (acc += el?.prizeLeft || 0), 0)
	);
	const prizesValueStill = computed(() =>
		prizeDropsData.value?.prizes?.reduce((acc, el) => (acc += (el?.prizeLeft || 0) * (el?.prizeValue || 0)), 0)
	);
	const prizesLeftStillByLevel = computed(() => {
		const result: { [key: string]: number } = { bronze: 0, silver: 0, gold: 0 };
		const levelMapping = { 1: "bronze", 2: "silver", 3: "gold" };

		prizeDropsData.value?.prizes?.forEach((el) => {
			const key = levelMapping[el.prizeLvl || 1];
			if (key) {
				result[key] += el?.prizeLeft || 0;
			}
		});

		return result;
	});
	const convertFinishedAt = computed(() => convertDateTimeFormat(prizeDropsData.value?.general?.finishedAt || ""));

	const timeFormat = computed(() =>
		dayjs(convertFinishedAt.value).diff(dayjs(), "d") >= 1 ? "D[d] HH[h] mm[m]" : "HH[h] mm[m] ss[s]"
	);

	const { durationLeft: durationLeftFinished, reset: resetFinished } = useCountdown({
		timestamp: convertFinishedAt.value,
		formatToken: separationTimer ? timeFormat.value : "DD[d] HH[h] mm[m]",
		isOptimized: isOptimizedTimer,
		onCountdownStop: () => {
			if (appInitData.value?.prizeDrops?.isActive && !isGuest.value) {
				appInitData.value.prizeDrops.isActive = false;

				if (route.path.includes(routeName)) {
					navigateTo("/");
				}
			}
		}
	});

	const { durationLeft: durationLeftNextStarted, reset: resetNextStarted } = useCountdown({
		timestamp: convertDateTimeFormat(prizeDropsData.value?.general?.nextPrizePoolStartedAt || ""),
		formatToken: separationTimer ? "HH:mm:ss" : "HH[h ]mm[m ]ss[s]"
	});

	const handleShowPopup = () => {
		loginGuard({
			success() {
				if (!prizeDropActive.value || prizeDropPopupCookie.value) {
					return;
				}
				prizeDropPopupCookie.value = "onExpired";
				open("LazyOModalPrizeDropsWelcome" as K);
			}
		});
	};

	watch(
		() => prizeDropsData.value?.general?.finishedAt,
		(value) => {
			resetFinished(convertDateTimeFormat(value || ""));
		}
	);

	watch(
		() => prizeDropsData.value?.general?.nextPrizePoolStartedAt,
		(value) => {
			resetNextStarted(convertDateTimeFormat(value || ""));
		}
	);

	return {
		pending,
		showActiveWidget,
		prizeDropActive,
		prizeDropsData,
		prizesLeftStill,
		prizesValueStill,
		durationLeftFinished,
		durationLeftNextStarted,
		prizesLeftStillByLevel,
		handleShowPopup
	};
};

export default usePrizeDrop;
